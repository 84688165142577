/* Helpers */
@mixin padding-top-list {
  @for $i from 1 through 10 {
    .pt-#{$i} {
      padding-top: #{$i}rem;
    }
  }
}

@include padding-top-list;

/* Global rewrites */
.alert-primary {
  color: #333;
  background-color: #f7f6f3;
  border-radius: 3px;
  border-color: #ffffff00;
  padding-right: 10px;
}

.card {
  border: none;
  border-radius: 3px;
  //background-color: #f7f6f3;
  background-color: inherit;
}

h1 {
  font-size: 2rem;
  font-family: "Catamaran", sans-serif;
}

.bg-light-yellow {
  background-color: #fef0cb;
}

/* Into component */
.searchBox {
  max-width: 800px;
}

.titleText {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.2;
}

/* Answers component */
.arrow-rank {
  cursor: pointer;
}

/* Header */
.header {
  padding-top: 5px;
  padding-right: 10px;
}

a.headerLink {
  color: rgb(55, 53, 47);
  text-decoration: none;
  padding: 5px;
  transition: 0.2s;
}

a.headerLink:hover {
  background: #37352f14;
  border-radius: 3px;
}

/* Animations */
.fade-in-top {
  -webkit-animation: fade-in-top 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-top 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-3-21 8:50:37
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/* ----------------------------------------------
 * Generated by Animista on 2020-3-21 8:55:36
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in-top
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
