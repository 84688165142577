@import url(https://fonts.googleapis.com/css2?family=Catamaran:wght@200&display=swap);
.pt-1{padding-top:1rem}.pt-2{padding-top:2rem}.pt-3{padding-top:3rem}.pt-4{padding-top:4rem}.pt-5{padding-top:5rem}.pt-6{padding-top:6rem}.pt-7{padding-top:7rem}.pt-8{padding-top:8rem}.pt-9{padding-top:9rem}.pt-10{padding-top:10rem}.alert-primary{color:#333;background-color:#f7f6f3;border-radius:3px;border-color:#ffffff00;padding-right:10px}.card{border:none;border-radius:3px;background-color:inherit}h1{font-size:2rem;font-family:"Catamaran", sans-serif}.bg-light-yellow{background-color:#fef0cb}.searchBox{max-width:800px}.titleText{text-align:center;font-size:1.5rem;font-weight:300;line-height:1.2}.arrow-rank{cursor:pointer}.header{padding-top:5px;padding-right:10px}a.headerLink{color:#37352f;text-decoration:none;padding:5px;transition:0.2s}a.headerLink:hover{background:#37352f14;border-radius:3px}.fade-in-top{-webkit-animation:fade-in-top 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;animation:fade-in-top 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both}@-webkit-keyframes fade-in-top{0%{transform:translateY(-50px);opacity:0}100%{transform:translateY(0);opacity:1}}@keyframes fade-in-top{0%{transform:translateY(-50px);opacity:0}100%{transform:translateY(0);opacity:1}}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  background-color: #fbfbfb;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
